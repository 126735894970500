import { mergeOverrides, useStyletron } from "baseui";
import type { ModalProps as BaseModalProps } from "baseui/modal";
import {
  Modal as BaseModal,
  ModalBody as BaseModalBody,
  ModalFooter as BaseModalFooter,
  ModalHeader as BaseModalHeader,
} from "baseui/modal";
import type { ReactNode } from "react";
import React from "react";
import { CloseIcon } from "../../icons/CloseIcon";
import { expandBorderRadii, expandMargin, paddingVertical } from "../../utils";
import { PlainButton } from "../button/PlainButton";
import { Override } from "baseui/overrides";
import { SharedStylePropsArg } from "baseui/popover";
export { ROLE as ModalRole } from "baseui/modal";

export type ModalProps = {
  /** Pass content for automatic formatting into the modal title */
  title?: ReactNode;
  /** Pass content for automatic formatting into the modal footer, right aligned */
  footer?: ReactNode;
  /** Pass content for automatic formatting into the modal footer, left aligned */
  footerLeft?: ReactNode;
  /** Take over rendering the entire modal body, escape any automatic formatting */
  raw?: boolean;
  "data-testid"?: string;
} & BaseModalProps &
  ModalBodyProps;

export type ModalBodyProps = {
  overrides?: {
    ModalBody?: Override<SharedStylePropsArg>;
  };
};

export const ModalHeader = (props: React.ComponentProps<typeof BaseModalHeader>) => {
  const [_, $theme] = useStyletron();
  return (
    <BaseModalHeader
      $as="header"
      {...props}
      $style={{
        ...$theme.typography.subHeading,
        ...expandMargin($theme.sizing.scale600),
        ...props.$style,
      }}
    />
  );
};

export const ModalBody = (props: React.ComponentProps<typeof BaseModalBody>) => {
  const [_, $theme] = useStyletron();
  return (
    <BaseModalBody
      $as="section"
      {...props}
      $style={{
        ...expandMargin($theme.sizing.scale600),
        color: $theme.colors.contentPrimary,
        ...props.$style,
      }}
    />
  );
};

export const ModalFooter = (props: React.ComponentProps<typeof BaseModalBody>) => {
  const [_, $theme] = useStyletron();
  return (
    <BaseModalFooter
      $as="footer"
      {...props}
      $style={{
        ...expandMargin($theme.sizing.scale600),
        ...paddingVertical(0),
        display: "flex",
        gap: $theme.sizing.scale500,
        justifyContent: "end",
        alignItems: "center",
        ...props.$style,
      }}
    />
  );
};

export const Modal = (props: ModalProps) => {
  const [css, $theme] = useStyletron();
  const overrides = mergeOverrides(
    {
      Close: {
        component: CloseButton,
      },
      Dialog: {
        props: {
          "data-testid": props["data-testid"],
        },
        style: {
          ...expandBorderRadii("8px"),
          boxShadow: $theme.lighting.depth4,
        },
      },
    },
    props.overrides as any
  );

  return (
    <BaseModal unstable_ModalBackdropScroll {...props} overrides={overrides}>
      {props.raw ? (
        props.children
      ) : (
        <>
          {props.title && <ModalHeader>{props.title}</ModalHeader>}
          <ModalBody $style={(overrides.ModalBody as any)?.style}>{props.children}</ModalBody>
          {(props.footer || props.footerLeft) && (
            <ModalFooter>
              {props.footerLeft && (
                <div
                  className={css({
                    marginRight: "auto",
                  })}
                >
                  {props.footerLeft}
                </div>
              )}
              {props.footer}
            </ModalFooter>
          )}
        </>
      )}
    </BaseModal>
  );
};

export const CloseButton = (props: any) => {
  const [css, $theme] = useStyletron();

  return (
    <PlainButton
      {...props}
      type="button"
      $style={{
        position: "absolute",
        top: $theme.sizing.scale500,
        right: $theme.sizing.scale500,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
      }}
    >
      <CloseIcon />
    </PlainButton>
  );
};
